'use client';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cn } from '@v2/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const Tabs = TabsPrimitive.Root;

const tabsListVariants = cva('inline-flex ', {
  variants: {
    variant: {
      default:
        'items-center justify-center h-10 rounded-md bg-muted p-1 text-muted-foreground',
      outline:
        'border-b border-gray-200 w-full justify-start pb-0 space-x-[24px]',
      // Add more variants here
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});
const tabsTriggerVariants = cva(
  'inline-flex items-center justify-center text-md font-semibold ',
  {
    variants: {
      variant: {
        default:
          'whitespace-nowrap rounded-sm px-3 py-1.5  ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm',
        outline:
          'px-0 py-1.5 ring-offset-background transition-all border-b-2 border-b-transparent text-gray-500 data-[state=active]:border-gray-800 data-[state=active]:text-gray-800   mb-[-2px]',
        // Add more variants here
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);
interface TabsListProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>,
    VariantProps<typeof tabsListVariants> {}

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  TabsListProps
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(tabsListVariants({ variant, className }))}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

interface TabsTriggerProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>,
    VariantProps<typeof tabsTriggerVariants> {}

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  TabsTriggerProps
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(tabsTriggerVariants({ variant, className }))}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'ring-offset-background focus-visible:ring-ring mt-6 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsContent, TabsList, TabsTrigger };
