import { Button, ChevronRight, Star01, Typography } from '@v2/ui';
import { cn, themeConfig } from '@v2/utils';
import Link from 'next/link';

import React from 'react';
type EventPreviewCardProps = {
  className?: string;
  children: React.ReactNode;
};
function Card({ className, children }: EventPreviewCardProps) {
  return (
    <li
      className={cn(
        'flex w-full justify-between gap-x-8 border-t border-gray-200 p-5 focus:ring',
        className
      )}
    >
      {children}
    </li>
  );
}
Card.displayName = Card;
const Row = ({
  title,
  description,
  descriptionClassName,
  className,
}: {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  className?: string;
  descriptionClassName?: string;
}) => {
  return (
    <div className={cn(className, 'text-start')}>
      <Typography
        variant="h4"
        size="tmd"
        className="mb-1 inline-flex font-semibold text-gray-800"
      >
        {title}
      </Typography>
      <Typography
        size="tsm"
        variant="p"
        className={cn('text-gray-500', descriptionClassName)}
      >
        {description}
      </Typography>
    </div>
  );
};
Row.displayName = Row;
const ActionButton = ({
  isFavorite,
  link,
  action,
}: {
  isFavorite?: boolean;
  link: string;
  action: () => void;
}) => {


  
  return (
    <>
      <div className="hidden justify-end md:flex md:flex-1">
        <Link href={link} prefetch={false} target="_blank">
          <Button
            variant="link"
            className="flex items-center font-semibold text-primary-500"
          >
            Build package{' '}
            <ChevronRight className="ml-2" size="16" stroke="#FA2846" />
          </Button>
        </Link>
      </div>
      <div className="col-span-1 flex items-start justify-end md:hidden">
        {/* <button onClick={action} className="cursor-pointer">
          <Star01
            stroke="#667085"
            size="24"
            fill={isFavorite ? themeConfig.theme.colors.gray[800] : 'unset'}
          />
        </button> */}
      </div>
    </>
  );
};
const CustomActionButton = ({
  isFavorite,
  link,
  action,
}: {
  isFavorite?: boolean;
  link: string;
  action: () => void;
}) => {
  return (
    <>
      <div className="hidden justify-end md:col-span-2 md:flex">
        <Link href={link} prefetch={false} target="_blank">
          <Button
            variant="link"
            className="flex items-center font-semibold text-primary-500"
          >
            Build package{' '}
            <ChevronRight className="ml-2" size="16" stroke="#FA2846" />
          </Button>
        </Link>
      </div>
    </>
  );
};
ActionButton.displayName = ActionButton;
export { ActionButton, Card, Row };
