import routes from '@config/routes';
import {
  CHECKOUT_START_DATETIME,
  PACKAGE_STORAGE,
  SELECTED_HOTEL_STORAGE,
  SELECTED_TICKET_STORAGE,
} from '@config/storageKeys';
import useStorage from '@hooks/useStorage';
import { useBuildPackagesStore } from '@store/build-package-store';
import { useCheckoutStepsStore } from '@store/checkout-steps-store';
import { links } from '@utils/navigation/links';
import { getShoppingCartById } from '@v2/action/shopping-cart/shopping-cart-action';
import { CheckoutPayload } from '@v2/action/shopping-cart/types';
import { setCookie } from 'cookies-next';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useTransition } from 'react';
import useSWR from 'swr';

export function useInitBuildPackage() {
  const searchParams = useSearchParams();
  const storage = useStorage();
  const router = useRouter();

  const [isPending, startTransition] = useTransition();

  const shoppingCartIdParam = searchParams?.get('shoppingCartId');
  const eventId = searchParams?.get('eventId');

  const shoppingCartId = useCheckoutStepsStore((state) => state.cartId);
  const { data, isLoading } = useSWR<CheckoutPayload>(
    shoppingCartIdParam
      ? routes.shoppingCartId(shoppingCartIdParam)
      : undefined,
    (url) => getShoppingCartById(shoppingCartIdParam!),
    { revalidateIfStale: false, revalidateOnFocus: false }
  );

  useEffect(() => {
    if (!isLoading && data) {
      const values = data.packageConfigDetails;

      console.log({ values });

      startTransition(() => {
        const isBundle =
          Boolean(data.selectedHotelDetails) &&
          Boolean(data.selectedTicketDetails);

        useBuildPackagesStore.setState({
          ...values,
          isBundle,
          // isBundle: selectedPackageType === 'packages',
        });

        if (data.selectedTicketDetails) {
          console.log(data.selectedTicketDetails, 'data.selectedTicketDetails');
          storage.setItem(
            SELECTED_TICKET_STORAGE,
            JSON.stringify(data.selectedTicketDetails)
          );
        }

        if (data.selectedHotelDetails) {
          storage.setItem(
            SELECTED_HOTEL_STORAGE,
            JSON.stringify(data.selectedHotelDetails)
          );
        }
        const selected = {
          tickets: Boolean(data.selectedTicketDetails),
          hotel: Boolean(data.selectedHotelDetails),
        };

        storage.setItem(
          PACKAGE_STORAGE,
          JSON.stringify({ ...values, selected })
        );
        setCookie(CHECKOUT_START_DATETIME, new Date().getTime());
        router.push(`${links.checkout}?eventId=${eventId}`);

        console.log({ data });
      });
    }
  }, [data, isLoading]);

  return { isLoading: isLoading && isPending };
}
