import { useWindowSizeContext } from '@contexts/window-size-context';
import {
  Building05,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  ScrollBar,
  Trash02,
  Typography,
} from '@v2/ui';
import { PaxCounter } from '@v2/views/buy/components/RoomConfigurator';
import { FormikProps } from 'formik';
import { useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { useCheckInOut } from '../../../hooks/use-hotel-info';
import { HeaderFormikProps } from '../Header';
import { InputWithLabel } from './InputWithLabel';

type Props = {
  formik: FormikProps<HeaderFormikProps>;
};

export function BaseRoomsFilter(props: Props) {
  const { formik } = props;
  const [isOpen, setIsOpen] = useState(false);

  const { setFieldValue, values } = formik;
  const matches = useMediaQuery('(min-width: 1024px)');

  const [{ formattedValue }] = useCheckInOut({
    roomConfigurations: values.roomConfigurations,
  });


  const handleAddRoom = () => {
    const { roomConfigurations } = values;
    setFieldValue('roomConfigurations', [
      ...roomConfigurations,
      {
        adults: 1,
        children: [],
        infants: 0,
      },
    ]);
  };


  return (
    <Popover open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <PopoverTrigger asChild>
        <InputWithLabel
          icon={
            <Building05
              className="text-gray-800"
              pathProps={{ stroke: 'currentColor' }}
            />
          }
          label={'Rooms'}
          value={formattedValue}
        />
      </PopoverTrigger>
      <PopoverContent className="popover-content-width-same-as-its-trigger" align="start">
        {!matches && (
          <div className="mb-3 flex items-center justify-between">
            <Typography size="tmd" className="font-semibold">
              Rooms
            </Typography>
            <Button
              type="button"
              variant="link"
              className="p-0"
              disabled={values.roomConfigurations.length >= 8}
              onClick={handleAddRoom}
            >
              + Add room
            </Button>
          </div>
        )}
        <ScrollArea className={`flex room-configuration-mobile-hotel w-full md:h-[600px] md:w-[552px]`}>
          <div className='flex flex-wrap gap-4'>
            {values.roomConfigurations?.map((configuration, roomIndex) => (
              <div
                key={roomIndex}
                className="h-[228px] w-full space-y-1.5 rounded-lg border border-gray-300 p-4 md:h-[240px] md:w-[240px]"
              >
                <div className="flex items-center justify-between">
                  <Typography
                    variant="h3"
                    size="tsm"
                    className="font-medium text-gray-500"
                  >{`Room ${roomIndex + 1}`}</Typography>
                  <button
                    className={
                      values.roomConfigurations.length === 1
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }
                    type="button"
                    tabIndex={0}
                    disabled={values.roomConfigurations.length === 1}
                    onClick={() => {
                      const rooms = [...values.roomConfigurations];
                      rooms.splice(roomIndex, 1);
                      setFieldValue('roomConfigurations', rooms);
                    }}
                  >
                    <Trash02
                      size="20"
                      className={
                        values.roomConfigurations.length === 1
                          ? 'text-gray-500'
                          : 'text-gray-700'
                      }
                    />
                  </button>
                </div>
                <div className="flex items-center justify-between">
                  <Typography
                    size="tmd"
                    variant="h4"
                    className="flex flex-col font-medium"
                  >
                    Adults
                    <Typography size="txs" className="mb-1 text-gray-500">
                      Age over 17
                    </Typography>
                  </Typography>
                  {/* eslint-disable-next-line max-len */}
                  <PaxCounter
                    value={configuration.adults}
                    onIcrement={() => {
                      setFieldValue(
                        `roomConfigurations.${roomIndex}.adults`,
                        configuration.adults + 1
                      );
                    }}
                    onDecrement={() => {
                      if (configuration.adults > 1) {
                        setFieldValue(
                          `roomConfigurations.${roomIndex}.adults`,
                          configuration.adults - 1
                        );
                      }
                    }}
                  />
                </div>
                <div className="flex items-center justify-between">
                  <Typography
                    size="tmd"
                    variant="h4"
                    className="flex flex-col font-medium"
                  >
                    Children
                    <Typography size="txs" className="mb-1 text-gray-500">
                      Age 2-17
                    </Typography>
                  </Typography>
                  {/* eslint-disable-next-line max-len */}
                  <PaxCounter
                    value={configuration.children.length}
                    onIcrement={() => {
                      const { children } =
                        values?.roomConfigurations?.[roomIndex];
                      setFieldValue(`roomConfigurations.${roomIndex}.children`, [
                        ...children,
                        0,
                      ]);
                    }}
                    onDecrement={() => {
                      const children = [
                        ...values?.roomConfigurations?.[roomIndex].children,
                      ];
                      children.pop();
                      setFieldValue(
                        `roomConfigurations.${roomIndex}.children`,
                        children
                      );
                    }}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <Typography
                    size="tmd"
                    variant="h4"
                    className="flex flex-col font-medium"
                  >
                    Infants
                    <Typography size="txs" className="mb-1 text-gray-500">
                      Age under 2
                    </Typography>
                  </Typography>
                  {/* eslint-disable-next-line max-len */}
                  <PaxCounter
                    value={configuration.infants}
                    onIcrement={() => {
                      setFieldValue(
                        `roomConfigurations.${roomIndex}.infants`,
                        configuration.infants + 1
                      );
                    }}
                    onDecrement={() => {
                      if (configuration.infants - 1 !== -1)
                        setFieldValue(
                          `roomConfigurations.${roomIndex}.infants`,
                          configuration.infants - 1
                        );
                    }}
                  />
                </div>
              </div>
            ))}

            {matches && (
              <div className="hidden h-[240px] w-[240px] items-center justify-center rounded-lg border border-dashed md:flex">
                <Button
                  type="button"
                  variant="link"
                  className="p-0"
                  onClick={handleAddRoom}
                  disabled={values.roomConfigurations.length >= 8}
                >
                  + Add new room
                </Button>
              </div>
            )}
          </div>
          <ScrollBar />
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
}
