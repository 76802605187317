'use client';
import routes from '@config/routes';
import {
  CHECKOUT_START_DATETIME,
  PACKAGE_STORAGE,
  SOCIAL_LOGIN_REDIRECT,
} from '@config/storageKeys';
import { useCurrencyContext } from '@contexts/currency-context';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';
import useMinimumPrice from '@hooks/useMinimumPrice';
import useStorage from '@hooks/useStorage';
import { serverFetcher } from '@instance';
import { type IFormValues } from '@interfaces/buildPackage';
import { type BaseProperty } from '@interfaces/expedia/property';
import { useBuildPackagesStore } from '@store/build-package-store';
import { useCheckoutStepsStore } from '@store/checkout-steps-store';
import { useIsClient } from '@uidotdev/usehooks';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { links } from '@utils/navigation/links';
import { Button } from '@v2/ui';
import { setCookie } from 'cookies-next';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';
import useSWR from 'swr';

export function MobileBottomHeader() {
  const storage = useStorage();
  const [currentPackageData, setCurrentPackageData] = useState<IFormValues>(
    JSON.parse(storage.getItem(PACKAGE_STORAGE) ?? '{}')
  );
  const searchParams = useSearchParams();
  const eventId = searchParams?.get('eventId');

  const base = useBuildPackagesStore((state) => ({
    startDate: state.hotelStartDate,
    endDate: state.hotelEndDate,
    location: state.location,
    roomConfigurations: state.roomConfigurations,
  }));

  const { data, isValidating } = useSWR<{
    error: [];
    properties: BaseProperty[];
  }>(
    routes.propertySearch,
    (url) =>
      serverFetcher(url, {
        body: {
          base,
          options: {
            requiredCurrency: 'USD',
            maxResult: 100,
            isBundle: currentPackageData?.selected.tickets,
          },
        },
      }),
    { revalidateIfStale: false, revalidateOnFocus: false }
  );

  const { hotelPrice } = useMinimumPrice(
    data?.properties,
    [],
    currentPackageData?.tickets
  );

  const isClient = useIsClient();

  const { priceRate, currency } = useCurrencyContext();
  const selectedTicketPrice = useBuildPackagesStore(state => state.selectedTicketPrice)

  const { packageGuests } = useBuildPackageBundle();

  const bottomTotalPriceLabel = currentPackageData?.selected.tickets
    ? 'Includes taxes, hotel & tickets'
    : 'Includes taxes & hotel';

  if (!isClient || isValidating) return null;

  const basePrice = currentPackageData?.selected.tickets
    ? hotelPrice + selectedTicketPrice
    : hotelPrice;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-20 w-full bg-gray-900">
      <div className="flex flex-col items-start justify-between gap-y-4 px-4 py-5 md:mx-auto md:max-w-[1400px] md:flex-row md:px-20">
        <div className="flex items-start justify-between gap-x-3 md:justify-start">
          <div>
            <h5 className="text-lg font-bold text-white">
              {formatCurrencyV2(
                basePrice / packageGuests,
                priceRate,
                currency,
                true,
                false
              )}{' '}
              per person
            </h5>

            <span className="text-sm font-medium text-gray-400">
              {formatCurrencyV2(basePrice, priceRate, currency, true, false)}{' '}
              in total. {bottomTotalPriceLabel}
            </span>
          </div>
        </div>

        <Link
          className="w-full"
          href={`${links.checkout}?eventId=${eventId}`}
          onClick={() => {
            useCheckoutStepsStore.setState({ hotelSelected: false });
            setCookie(CHECKOUT_START_DATETIME, new Date().getTime());
            storage.setItem(
              SOCIAL_LOGIN_REDIRECT,
              `${links.checkout}?eventId=${eventId}`
            );
          }}
        >
          <Button
            variant="ghost"
            className="w-full border border-gray-700 bg-transparent text-white"
          >
            {"I don't need a hotel"}
          </Button>
        </Link>
      </div>
    </div>
  );
}
