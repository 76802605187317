import { useAffiliateLink } from '@hooks/use-affiliate-link';
import { v2Links } from '@utils/navigation/links';
import { Button, Copy01, Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger } from '@v2/ui';
import { cn } from '@v2/utils';
import { forwardRef } from 'react';
import { ColumnItem } from './components/ColumnItem/ColumnItem';

interface RowProps {
  title: string;
  description: string;
  className?: string;
}

export const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="w-full rounded-lg border-b border-l border-r border-gray-200">
      {children}
    </div>
  );
};
export const Card = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>
>(({ children, ...rest }, ref) => {
  return (
    <div
      className="grid w-full grid-cols-8 border-t border-gray-200 p-5 md:grid-cols-10"
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
});

export const Row = ({ title, description, className }: RowProps) => {
  return (
    <div className={className}>
      <ColumnItem title={title} description={description} />
    </div>
  );
};

export const Header = ({ children }) => {
  return (
    <div className="w-full rounded-t-lg border-l border-r border-t border-gray-200 bg-gray-25 px-5 py-2">
      <p className="text-sm text-gray-500">{children}</p>
    </div>
  );
};

export const Action = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className: string;
}) => {
  return <div className={className}>{children}</div>;
};



type CopyLinkProps = { className?: string, triggerClassName?: string, eventLink: string, eventId: number }
export const CopyLink = ({ className, triggerClassName, eventId, eventLink }: CopyLinkProps ) => {


  const { isLoading, link, onCopyButtonClick, } = useAffiliateLink();


  if (isLoading || !link) {
    return null;
  }
  return (


    <TooltipProvider>
      <Tooltip>

        <TooltipTrigger
          className={cn('flex items-center justify-center', triggerClassName)}
        >
          <Button
            size='md'
            variant='ghost'
            className={cn('md:border border-gray-300 rounded-full flex items-center justify-center size-6 md:size-10 p-0 text-gray-500 md:text-gray-800', className)}


            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onCopyButtonClick({
                baseUrl: v2Links.buy,
                eventId,
                eventLink,
              })

            }}
          >
            <Copy01 className='flex-shrink-0' size='20' />
          </Button>
        </TooltipTrigger>

        <TooltipContent
          className={cn(
            'flex min-w-auto flex-col items-center px-3 py-2 bg-gray-800'
          )}
        >
          <p className="text-xs font-semibold text-white">Copy event link</p>
          <TooltipArrow className='fill-gray-800  stroke-gray-800' />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>

  );
};